import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IssuesHeader from "../components/issues-header"
import IssueCards from "../components/issue-cards"

const IssuesPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues" />
    <IssuesHeader />
    <IssueCards />
  </Layout>
)

export default IssuesPage